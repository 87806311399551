<template>
  <div class="allhed">
    <span class="username" style="float: left;">
      <img style="  height: 50px;
   width: auto;" :src="logo" alt />{{ username }}</span>
    <span class="h3">{{ title }}</span>

    <span style="float: right;">
      <img src="@/img/logo.png" alt />
      盘工-生产管理
    </span>
    <div class="offall">
      <el-button v-if="!_isMobile" type="success" @click="goto('/statistics')" round>首页</el-button>
      <el-button v-if="!_isMobile" type="success" @click="goto('/orderOverview')" round>订单看板</el-button>
      <el-button v-if="!_isMobile" type="success" @click="goto('/home')" round>设备看板</el-button>
<!--      <el-button v-if="!_isMobile" type="success" @click="goto('/device')" round>生产看板</el-button>-->
      <el-button v-if="!_isMobile" type="success" @click="goto('/personnelOverview')" round>人员看板</el-button>
      <el-button v-if="!_isMobile" type="primary" @click="offall" round>进入主控台</el-button>
      <el-button v-else type="primary" @click="offphone" round>进入主控台</el-button>
      <el-button v-if="!_isMobile" type="primary" @click="showScreen" round>
        {{ !Screen ? '全屏' : '关闭全屏' }}
      </el-button>
      <el-button v-if="!_isMobile" type="primary" @click="enlarge" round>放大</el-button>
      <el-button v-if="!_isMobile" type="primary" @click="narrow" round>缩小</el-button>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "allhed",
  props: {
    title: {
      type: String,
      default: '机床设备实时看板'
    },
    boxSize: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      username: Cookies.get('userNane'),
      logo: Cookies.get('logo'),
      Screen: false
    };
  },
  methods: {
    showScreen () {
      var docElm = document.documentElement;
      if (!this.Screen) {
        // 全屏操作

        //W3C
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        }

        //FireFox
        else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        }

        //Chrome等
        else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        }

        //IE11
        else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
        this.Screen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        //FireFox
        else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        //Chrome等
        else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        //IE11
        else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.Screen = false;
      }

    },
    goto (url) {
      this.$router.push(url);
    },
    offall () {
      this.$router.push('/sc/companyOrder');
    },
    offphone () {
      this.$router.push('phoneProductionOrder');
    },
    enlarge () {
      if (this.boxSize > 0) {
        this.boxSize = this.boxSize - 1;
      } else {
        this.$message({
          message: '已经是最大了',
          type: 'warning'
        });
      }

    },
    narrow () {
      if (this.boxSize < 5) {
        this.boxSize = this.boxSize + 1;
      } else {
        this.$message({
          message: '已经是最小了',
          type: 'warning'
        });
      }
    }
  },
  computed: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  }
};
</script>

<style scoped>
.allhed {
  width: 100%;
  text-align: center;
  color: white;
  height: 100px;
  line-height: 100px;
  border-bottom: 3px solid white;
  padding: 0 30px;
  box-sizing: border-box;
  position: relative;
}

.username {
  font-size: 32px;
}

.offall {
  position: absolute;
  top: 110px;
  right: 0;
  height: 32px;
  font-size: 0;
  line-height: 32px;
}

.allhed img {
  width: 40px;
  height: 40px;
  margin: 0px 10px;
  transform: translateY(10px);
}

.allhed .h3 {
  font-weight: bold;
  font-size: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
